// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom"; // React Router for navigation
// import axios from "axios";
// import { useDispatchCart } from "../../../store/ContextReducer"; // Context API or Redux for state management
// import { useAuth } from "../../../store/AuthContext"; // Your auth management context (replace as needed)
// import { googleMapAPI, ApiPath } from "../../../config";
// import { useLocation } from "react-router-dom";
// import "./cart.css";
// import { myColors } from "../../../contants/Colors";
// import { toast } from "react-toastify";
// import { ArrowBackIosNew } from "@mui/icons-material";
// import AdjustIcon from "@mui/icons-material/Adjust";


// const OrderSummary = ({ route }) => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const dispatch = useDispatchCart();
//   const { menuData, selectedAddress, totalAmount, discount, subtotal, GST } =
//     location.state || {};
//   const [isLoading, setIsLoading] = useState(false);
//   const [distance, setDistance] = useState(0);
//   const [deliveryCharges, setDeliveryCharges] = useState(50);
//   const [finalAmount, setFinalAmount] = useState(totalAmount);
//   const { login, isAuthenticated, user } = useAuth();

//   //   console.log('menuData',menuData);

//   useEffect(() => {
//     // Alert user when they try to reload the page
//     const handleBeforeUnload = (event) => {
//       event.preventDefault();
//       event.returnValue = ""; // Chrome requires returnValue to be set
//     };

//     window.addEventListener("beforeunload", handleBeforeUnload);

//     // Cleanup the event listener on component unmount
//     return () => {
//       window.removeEventListener("beforeunload", handleBeforeUnload);
//     };
//   }, []);

//   const calculateDeliveryCharges = (distance, subtotal) => {
//     let deliveryCharge = 0;

//     if (distance < 100) {
//       deliveryCharge = 49;
//     } else if (distance >= 100 && distance < 200) {
//       deliveryCharge = 69;
//     } else if (distance >= 200 && distance < 500) {
//       deliveryCharge = 99;
//     } else if (distance >= 500 && distance < 700) {
//       deliveryCharge = 109;
//     } else if (distance >= 700 && distance < 1000) {
//       deliveryCharge = 119;
//     } else if (distance >= 1000) {
//       deliveryCharge = 129;
//     }

//     if (subtotal > 1000) {
//       deliveryCharge = 0;
//     }

//     return deliveryCharge;
//   };

//   const CurrentCafeLocation =
//     "16-11-741/D/106, Shalivahana Nagar Malakpet, Hyderabad - 500036, Telangana";

//   useEffect(() => {
//     const fetchDistance = async () => {
//       try {
//         const cafeLocation = CurrentCafeLocation;
//         const userEmail = localStorage.getItem("userEmail");

//         if (selectedAddress) {
//           const userLocation = `${selectedAddress.Location}, ${selectedAddress.City}, ${selectedAddress.Pincode}`;

//           const service = new window.google.maps.DistanceMatrixService();

//           service.getDistanceMatrix(
//             {
//               origins: [cafeLocation],
//               destinations: [userLocation],
//               travelMode: window.google.maps.TravelMode.DRIVING,
//             },
//             (response, status) => {
//               if (status === window.google.maps.DistanceMatrixStatus.OK) {
//                 const distanceInMeters =
//                   response.rows[0].elements[0].distance.value;
//                 const distance = distanceInMeters / 1000;
//                 setDistance(distance);
//                 console.log("Distance:", distance);

//                 const deliveryCharge = calculateDeliveryCharges(
//                   distance,
//                   subtotal
//                 );
//                 setDeliveryCharges(deliveryCharge);
//               } else {
//                 console.error("Error calculating distance:", status);
//                 setDeliveryCharges(50);
//               }
//             }
//           );
//         } else {
//           setDeliveryCharges(50);
//         }
//       } catch (error) {
//         console.error("Error fetching distance:", error);
//         setDistance(0);
//         setDeliveryCharges(50);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchDistance();
//   }, [selectedAddress]);

//   const handleOrderPlacement = async (menuData, selectedAddress, amount) => {
//     console.log("hello,541 handleOrderPlacement");
    
//     try {
//       const userEmail = localStorage.getItem("userEmail");
// console.log("542",userEmail);
// console.log("543",menuData, selectedAddress,);

//       const responses = await axios.post(
//         `${ApiPath}jaavapotorders/api/orders`,
//         {
//           order_data: [menuData, selectedAddress],
//           email: userEmail,
//         }
//       );

//       const responseToKitchen = await axios.post(
//         `${ApiPath}jaavapotemailnotification/send-email`,
//         {
//           emailid: "orders@thejaavapot.com",
//           order_data: [menuData, selectedAddress],
//         }
//       );

//       console.log("559",responses.status,responseToKitchen.status);
      
//       if (responses.status === 200 && responseToKitchen.status === 200) {
//         dispatch({ type: "DROP" });
//         toast.success(
//           "Your order has been successfully placed and will be delivered soon."
//         );
//         navigate("/orderhistory");
//         // navigate("/orderhistory", { amount });
//         // navigate("/");
//         console.log("successfully");
//       }
//     } catch (error) {
//       console.error("Order placement or email notification failed", error);
//     }
//   };

//   const handleCheckOut = async (amount) => {
//     // handleOrderPlacement(menuData, selectedAddress, amount);
//     try {
//       const {
//         data: { key },
//       } = await axios.get(`${ApiPath}jaavapotpayment/api/getkey`);
//       const {
//         data: { order },
//       } = await axios.post(`${ApiPath}jaavapotpayment/checkout`, { amount });

//       const options = {
//         key,
//         amount: order.amount,
//         currency: "INR",
//         name: "Jaavapot",
//         description: "Food ordering",
//         order_id: order.id,
//         handler: async function (response) {
//           let userEmail = localStorage.getItem("userEmail");
//           let responses = await axios.post(
//             `${ApiPath}jaavapotorders/api/orders`,
//             {
//               order_data: [menuData, selectedAddress],
//               email: userEmail,
//             }
//           );
  
//           let responseTokitchen = await axios.post(
//          `${ApiPath}jaavapotemailnotification/send-email`,
//             {
//               // emailid: "1mohsinsangram@gmail.com",       
//               emailid: "orders@thejaavapot.com",       
//               order_data:[menuData, selectedAddress]
//             }
//             );
            
//             // console.log(responses)
      
//           // console.log('responsesToKitchen',responses.status,);
//           if (responses.status === 200 && responseTokitchen.status===200) {
//             dispatch({ type: "DROP" });
//             toast.success("Your Order is Succefully Placed and soon it will be deliver");
//             // alert("Your Order is Succefully Placed and soon it will be deliver");
//             navigate('/orderhistory')
//           }},
//         prefill: {
//           name: selectedAddress.name,
//           email: selectedAddress.delivery_email,
//           contact: selectedAddress.delivery_phone,
//         },
//         notes: {
//           city: selectedAddress.City,
//           address: selectedAddress.Location,
//           pincode: selectedAddress.Pincode,
//           landmark: selectedAddress.Landmark,
//         },
//         theme: { color: "#FD7E4B" },
//       };

//       const rzp = new window.Razorpay(options);
//       rzp.open();

      

//       // rzp.on("payment.success", (response) => {
//       //   console.log("success");
        
//       //   toast.success(`Payment Successful: ${response.razorpay_payment_id}`);
//       //   handleOrderPlacement(menuData, selectedAddress, finalAmount);
//       // });

//       // rzp.on("payment.failed", (response) => {
//       //   toast.error(`Payment Failed: ${response.error.description}`);
//       // });
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     const roundedAmount = Math.round(totalAmount + deliveryCharges);
//     setFinalAmount(roundedAmount);
//   }, [totalAmount, deliveryCharges]);

//   if (isLoading) {
//     return (
//       <div className="loading-container">
//         <div className="spinner"></div>
//         <p>Loading...</p>
//       </div>
//     );
//   }

//   return (
//     <div className="my" style={{ backgroundColor: "#FDF0DD" }}>
//       <div style={{ display: "flex", justifyContent: "space-between" }}>
//         <p
//           style={{
//             fontWeight: 700,
//             fontFamily: "Arial",
//             fontSize: "36px",
//             fontStyle: "italic",
//             color: "#FD7E4B",
//           }}
//         >
//           Order Summary
//         </p>
//         <div
//           onClick={() => navigate(-1)}
//           //  onClick={()=> navigate('/milletproducts')}
//           style={{
//             display: "flex",
//             alignItems: "center",
//             cursor: "pointer",
//             backgroundColor: "#FF9A7233",
//             padding: "10px",
//             borderRadius: "15px",
//           }}
//         >
//           <ArrowBackIosNew style={{ color: "#000", fontSize: "20px" }} />
//           <span
//             style={{
//               marginLeft: "8px",
//               fontSize: "16px",
//               color: "#0D0D0D",
//               fontWeight: 400,
//               fontFamily: "Arial",
//             }}
//           >
//             Back To Cart
//           </span>
//         </div>
//       </div>
//       {menuData.length === 0 ? (
//         <div className="empty-cart">
//           <p>Your Cart is Empty!</p>
//         </div>
//       ) : (
//         <div className="menu-list">
//           <div>
//             {menuData.length > 0 && (
//               <div className="cart-headings">
//                 <div className="heading-image">
//                   <strong>Picture</strong>
//                 </div>
//                 <div className="heading-name">
//                   <strong>Name</strong>
//                 </div>
//                 <div className="heading-base-price">
//                   <strong>Base Price</strong>
//                 </div>
//                 {/* <div className="heading-quantity"><strong>Quantity</strong></div> */}
//                 <div className="heading-price">
//                   <strong>Price</strong>
//                 </div>
//                 <div className="heading-tax">
//                   <strong>Other</strong>
//                 </div>
//                 {/* <div className="heading-tax"><strong>Tax (18%)</strong></div> */}
//                 <div className="heading-total">
//                   <strong>Total</strong>
//                 </div>
//               </div>
//             )}

//             {menuData.map((item, index) => (
//               <div className="cart-item" key={index}>
//                 <div className="heading-image">
//                   <img src={item.img} alt={item.name} className="item-image" />
//                 </div>
//                 <div className="heading-name">
//                   <h4 className="valueStylesName">{item.name}</h4>
//                 </div>
//                 <div className="heading-base-price">
//                   <p className="valueStyles">₹{item.basicPrice}</p>
//                 </div>

//                 <div className="heading-price">
//                   <p className="valueStyles">₹ {item.basicPrice * item.qty}</p>
//                 </div>
//                 <div className="heading-tax">
//                   <p className="valueStyles">
//                     ₹ {(item.basicPrice * item.qty * 0.18).toFixed(2)}
//                   </p>
//                 </div>
//                 <div className="heading-total">
//                   <p className="valueStyles">
//                     ₹ {(item.basicPrice * item.qty * 1.18).toFixed(2)}
//                   </p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       )}
//       <div className="address-container">
//         <p
//           style={{
//             fontWeight: 700,
//             fontFamily: "Arial",
//             fontSize: "36px",
//             fontStyle: "italic",
//             color: "#FD7E4B",
//           }}
//         >
//           Delivery Address
//         </p>
//         {selectedAddress ? (
//           <div className="address-item" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
//             <div>
//               <p>{selectedAddress.name}</p>
//               <p>{`${selectedAddress.Location}, ${selectedAddress.City}, ${selectedAddress.State} - ${selectedAddress.Pincode}`}</p>
//               <p>{`Phone: ${selectedAddress.delivery_phone}`}</p>
//               {selectedAddress.delivery_email && (
//                 <p>{`Email: ${selectedAddress.delivery_email}`}</p>
//               )}
//             </div>
//             <div className="icon-container">
//     <AdjustIcon sx={{ color: "#F67939", fontSize: "50px" }} />
//   </div>
//           </div>
//         ) : (
//           <p>No Address Selected</p>
//         )}
//       </div>

//       <div className="summary-box">
//         <div className="summary-row">
//           <p>Subtotal</p>
//           <p>{subtotal} ₹</p>
//         </div>
//         <div className="summary-row">
//           <p>Delivery Charge</p>
//           <p>{deliveryCharges} ₹</p>
//         </div>
//         <div className="summary-row">
//           <p>Other</p>
//           <p>{GST.toFixed(2)} ₹</p>
//         </div>
//         <div className="summary-row">
//           <p>Discount</p>
//           <p>{discount} ₹</p>
//         </div>
//         <div className="summary-row total-amount">
//           <p
//             style={{
//               color: myColors.primaryOrange,
//               fontWeight: "700",
//               fontSize: "24px",
//             }}
//           >
//             Total Amount
//           </p>
//           <p
//             style={{
//               color: myColors.primaryOrange,
//               fontWeight: "700",
//               fontSize: "24px",
//             }}
//           >
//             {finalAmount} ₹
//           </p>
//         </div>
//         <div style={{display:'flex',justifyContent:'center'}}>
//               <button 
//     style={{
//       display: 'flex', 
//       justifyContent: 'space-between', 
//       alignItems: 'center', 
//       fontSize: '24px', 
//       fontWeight: '600', 
//       borderRadius: '20px', 
//       maxWidth: '490px', 
//       padding: '10px 40px', 
//       width: '100%'
//     }} 
//     onClick={() => handleCheckOut(finalAmount)}
//   >
//     <span style={{ marginRight: 'auto' }}>Place Order</span>
//     <span>₹{finalAmount}</span>
//   </button>
//   </div>
//       </div>
//     </div>
//   );
// };

// export default OrderSummary;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // React Router for navigation
import axios from "axios";
import { useDispatchCart } from "../../../store/ContextReducer"; // Context API or Redux for state management
import { useAuth } from "../../../store/AuthContext"; // Your auth management context (replace as needed)
import { googleMapAPI, ApiPath } from "../../../config";
import { useLocation } from "react-router-dom";
import "./cart.css";
import { myColors } from "../../../contants/Colors";
import { toast } from "react-toastify";
import { ArrowBackIosNew } from "@mui/icons-material";
import AdjustIcon from "@mui/icons-material/Adjust";


const OrderSummary = ({ route }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatchCart();
  const { menuData, selectedAddress, totalAmount, discount, subtotal, GST } =
    location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [distance, setDistance] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(50);
  const [finalAmount, setFinalAmount] = useState(totalAmount);
  const { login, isAuthenticated, user } = useAuth();

  //   console.log('menuData',menuData);
  if(!isAuthenticated){
    toast.error("Please login to order")
    navigate('/AuthPage')
  }

  useEffect(() => {
    // Alert user when they try to reload the page
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Chrome requires returnValue to be set
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const calculateDeliveryCharges = (distance, subtotal) => {
    let deliveryCharge = 0;

    if (distance < 100) {
      deliveryCharge = 49;
    } else if (distance >= 100 && distance < 200) {
      deliveryCharge = 69;
    } else if (distance >= 200 && distance < 500) {
      deliveryCharge = 99;
    } else if (distance >= 500 && distance < 700) {
      deliveryCharge = 109;
    } else if (distance >= 700 && distance < 1000) {
      deliveryCharge = 119;
    } else if (distance >= 1000) {
      deliveryCharge = 129;
    }

    if (subtotal > 1000) {
      deliveryCharge = 0;
    }

    return deliveryCharge;
  };

  const CurrentCafeLocation =
    "16-11-741/D/106, Shalivahana Nagar Malakpet, Hyderabad - 500036, Telangana";

  useEffect(() => {
    const fetchDistance = async () => {
      try {
        const cafeLocation = CurrentCafeLocation;
        const userEmail = localStorage.getItem("userEmail");

        if (selectedAddress) {
          const userLocation = `${selectedAddress.Location}, ${selectedAddress.City}, ${selectedAddress.Pincode}`;

          const service = new window.google.maps.DistanceMatrixService();

          service.getDistanceMatrix(
            {
              origins: [cafeLocation],
              destinations: [userLocation],
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
              if (status === window.google.maps.DistanceMatrixStatus.OK) {
                const distanceInMeters =
                  response.rows[0].elements[0].distance.value;
                const distance = distanceInMeters / 1000;
                setDistance(distance);
                console.log("Distance:", distance);

                const deliveryCharge = calculateDeliveryCharges(
                  distance,
                  subtotal
                );
                setDeliveryCharges(deliveryCharge);
              } else {
                console.error("Error calculating distance:", status);
                setDeliveryCharges(50);
              }
            }
          );
        } else {
          setDeliveryCharges(50);
        }
      } catch (error) {
        console.error("Error fetching distance:", error);
        setDistance(0);
        setDeliveryCharges(50);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDistance();
  }, [selectedAddress]);

  const handleOrderPlacement = async (menuData, selectedAddress, amount) => {
    console.log("hello,541 handleOrderPlacement");
    
    try {
      const userEmail = localStorage.getItem("userEmail");
console.log("542",userEmail);
console.log("543",menuData, selectedAddress,);

      const responses = await axios.post(
        `${ApiPath}jaavapotorders/api/orders`,
        {
          order_data: [menuData, selectedAddress],
          email: userEmail,
        }
      );

      const responseToKitchen = await axios.post(
        `${ApiPath}jaavapotemailnotification/send-email`,
        {
          emailid: "orders@thejaavapot.com",
          order_data: [menuData, selectedAddress],
        }
      );

      console.log("559",responses.status,responseToKitchen.status);
      
      if (responses.status === 200 && responseToKitchen.status === 200) {
        dispatch({ type: "DROP" });
        toast.success(
          "Your order has been successfully placed and will be delivered soon."
        );
        navigate("/orderhistory");
        // navigate("/orderhistory", { amount });
        // navigate("/");
        console.log("successfully");
      }
    } catch (error) {
      console.error("Order placement or email notification failed", error);
    }
  };

  const handleCheckOut = async (amount) => {
    try {
      // Check if the selected city is not Hyderabad
      if (selectedAddress.City.toLowerCase() !== "hyderabad") {
        // Check if any item in the cart is from the ReadyToEat category
        const hasReadyToEatItem = menuData.some(
          (item) => item.maincategory === "ReadyToEat"
        );
  
        if (hasReadyToEatItem) {
          // Show alert if ReadyToEat items are in the cart for non-Hyderabad locations
          toast.error(
            "Currently, ReadyToEat items are available only in Hyderabad. You can order ReadyToCook items for other locations."
          );
          return; // Prevent further execution
        }
      }
  
      const {
        data: { key },
      } = await axios.get(`${ApiPath}jaavapotpayment/api/getkey`);
      const {
        data: { order },
      } = await axios.post(`${ApiPath}jaavapotpayment/checkout`, { amount });
  
      const options = {
        key,
        amount: order.amount,
        currency: "INR",
        name: "Jaavapot",
        description: "Food ordering",
        order_id: order.id,
        handler: async function (response) {
          let userEmail = localStorage.getItem("userEmail");
          let responses = await axios.post(`${ApiPath}jaavapotorders/api/orders`, {
            order_data: [menuData, selectedAddress],
            email: userEmail,
          });
  
          let responseToKitchen = await axios.post(
            `${ApiPath}jaavapotemailnotification/send-email`,
            {
              emailid: "orders@thejaavapot.com",
              order_data: [menuData, selectedAddress],
            }
          );
  
          if (responses.status === 200 && responseToKitchen.status === 200) {
            dispatch({ type: "DROP" });
            toast.success(
              "Your Order is Successfully Placed and will be delivered soon."
            );
            navigate("/orderhistory");
          }
        },
        prefill: {
          name: selectedAddress.name,
          email: selectedAddress.delivery_email,
          contact: selectedAddress.delivery_phone,
        },
        notes: {
          city: selectedAddress.City,
          address: selectedAddress.Location,
          pincode: selectedAddress.Pincode,
          landmark: selectedAddress.Landmark,
        },
        theme: { color: "#FD7E4B" },
      };
  
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error(error);
    }
  };
  

  // const handleCheckOut = async (amount) => {
  //   // handleOrderPlacement(menuData, selectedAddress, amount);
  //   try {
  //     const {
  //       data: { key },
  //     } = await axios.get(`${ApiPath}jaavapotpayment/api/getkey`);
  //     const {
  //       data: { order },
  //     } = await axios.post(`${ApiPath}jaavapotpayment/checkout`, { amount });

  //     const options = {
  //       key,
  //       amount: order.amount,
  //       currency: "INR",
  //       name: "Jaavapot",
  //       description: "Food ordering",
  //       order_id: order.id,
  //       handler: async function (response) {
  //         let userEmail = localStorage.getItem("userEmail");
  //         let responses = await axios.post(
  //           `${ApiPath}jaavapotorders/api/orders`,
  //           {
  //             order_data: [menuData, selectedAddress],
  //             email: userEmail,
  //           }
  //         );
  
  //         let responseTokitchen = await axios.post(
  //        `${ApiPath}jaavapotemailnotification/send-email`,
  //           {
  //             // emailid: "1mohsinsangram@gmail.com",       
  //             emailid: "orders@thejaavapot.com",       
  //             order_data:[menuData, selectedAddress]
  //           }
  //           );
            
  //           // console.log(responses)
      
  //         // console.log('responsesToKitchen',responses.status,);
  //         if (responses.status === 200 && responseTokitchen.status===200) {
  //           dispatch({ type: "DROP" });
  //           toast.success("Your Order is Succefully Placed and soon it will be deliver");
  //           // alert("Your Order is Succefully Placed and soon it will be deliver");
  //           navigate('/orderhistory')
  //         }},
  //       prefill: {
  //         name: selectedAddress.name,
  //         email: selectedAddress.delivery_email,
  //         contact: selectedAddress.delivery_phone,
  //       },
  //       notes: {
  //         city: selectedAddress.City,
  //         address: selectedAddress.Location,
  //         pincode: selectedAddress.Pincode,
  //         landmark: selectedAddress.Landmark,
  //       },
  //       theme: { color: "#FD7E4B" },
  //     };

  //     const rzp = new window.Razorpay(options);
  //     rzp.open();

      

  //     // rzp.on("payment.success", (response) => {
  //     //   console.log("success");
        
  //     //   toast.success(`Payment Successful: ${response.razorpay_payment_id}`);
  //     //   handleOrderPlacement(menuData, selectedAddress, finalAmount);
  //     // });

  //     // rzp.on("payment.failed", (response) => {
  //     //   toast.error(`Payment Failed: ${response.error.description}`);
  //     // });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    const roundedAmount = Math.round(totalAmount + deliveryCharges);
    setFinalAmount(roundedAmount);
  }, [totalAmount, deliveryCharges]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="my" style={{ backgroundColor: "#FDF0DD" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p
          style={{
            fontWeight: 700,
            fontFamily: "Arial",
            fontSize: "36px",
            fontStyle: "italic",
            color: "#FD7E4B",
          }}
        >
          Order Summary
        </p>
        <div
          onClick={() => navigate(-1)}
          //  onClick={()=> navigate('/milletproducts')}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: "#FF9A7233",
            padding: "10px",
            borderRadius: "15px",
          }}
        >
          <ArrowBackIosNew style={{ color: "#000", fontSize: "20px" }} />
          <span
            style={{
              marginLeft: "8px",
              fontSize: "16px",
              color: "#0D0D0D",
              fontWeight: 400,
              fontFamily: "Arial",
            }}
          >
            Back To Cart
          </span>
        </div>
      </div>
      {menuData.length === 0 ? (
        <div className="empty-cart">
          <p>Your Cart is Empty!</p>
        </div>
      ) : (
        <div className="menu-list">
          <div>
            {menuData.length > 0 && (
              <div className="cart-headings">
                <div className="heading-image">
                  <strong>Picture</strong>
                </div>
                <div className="heading-name">
                  <strong>Name</strong>
                </div>
                <div className="heading-base-price">
                  <strong>Base Price</strong>
                </div>
                {/* <div className="heading-quantity"><strong>Quantity</strong></div> */}
                <div className="heading-price">
                  <strong>Price</strong>
                </div>
                <div className="heading-tax">
                  <strong>Other</strong>
                </div>
                {/* <div className="heading-tax"><strong>Tax (18%)</strong></div> */}
                <div className="heading-total">
                  <strong>Total</strong>
                </div>
              </div>
            )}

            {menuData.map((item, index) => (
              <div className="cart-item" key={index}>
                <div className="heading-image">
                  <img src={item.img} alt={item.name} className="item-image" />
                </div>
                <div className="heading-name">
                  <h4 className="valueStylesName">{item.name}</h4>
                </div>
                <div className="heading-base-price">
                  <p className="valueStyles">₹{item.basicPrice}</p>
                </div>

                <div className="heading-price">
                  <p className="valueStyles">₹ {item.basicPrice * item.qty}</p>
                </div>
                <div className="heading-tax">
                  <p className="valueStyles">
                    ₹ {(item.basicPrice * item.qty * 0.18).toFixed(2)}
                  </p>
                </div>
                <div className="heading-total">
                  <p className="valueStyles">
                    ₹ {(item.basicPrice * item.qty * 1.18).toFixed(2)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="address-container">
        <p
          style={{
            fontWeight: 700,
            fontFamily: "Arial",
            fontSize: "36px",
            fontStyle: "italic",
            color: "#FD7E4B",
          }}
        >
          Delivery Address
        </p>
        {selectedAddress ? (
          <div className="address-item" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <div>
              <p>{selectedAddress.name}</p>
              <p>{`${selectedAddress.Location}, ${selectedAddress.City}, ${selectedAddress.State} - ${selectedAddress.Pincode}`}</p>
              <p>{`Phone: ${selectedAddress.delivery_phone}`}</p>
              {selectedAddress.delivery_email && (
                <p>{`Email: ${selectedAddress.delivery_email}`}</p>
              )}
            </div>
            <div className="icon-container">
    <AdjustIcon sx={{ color: "#F67939", fontSize: "50px" }} />
  </div>
          </div>
        ) : (
          <p>No Address Selected</p>
        )}
      </div>

      <div className="summary-box">
        <div className="summary-row">
          <p>Subtotal</p>
          <p>{subtotal} ₹</p>
        </div>
        <div className="summary-row">
          <p>Delivery Charge</p>
          <p>{deliveryCharges} ₹</p>
        </div>
        <div className="summary-row">
          <p>Other</p>
          <p>{GST.toFixed(2)} ₹</p>
        </div>
        <div className="summary-row">
          <p>Discount</p>
          <p>{discount} ₹</p>
        </div>
        <div className="summary-row total-amount">
          <p
            style={{
              color: myColors.primaryOrange,
              fontWeight: "700",
              fontSize: "24px",
            }}
          >
            Total Amount
          </p>
          <p
            style={{
              color: myColors.primaryOrange,
              fontWeight: "700",
              fontSize: "24px",
            }}
          >
            {finalAmount} ₹
          </p>
        </div>
        <div style={{display:'flex',justifyContent:'center'}}>
              <button 
    style={{
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      fontSize: '24px', 
      fontWeight: '600', 
      borderRadius: '20px', 
      maxWidth: '490px', 
      padding: '10px 40px', 
      width: '100%'
    }} 
    onClick={() => handleCheckOut(finalAmount)}
  >
    <span style={{ marginRight: 'auto' }}>Place Order</span>
    <span>₹{finalAmount}</span>
  </button>
  </div>
      </div>
    </div>
  );
};

export default OrderSummary;
