// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { GiHamburgerMenu } from 'react-icons/gi';
// import './navbar.css'
// import Badge from "react-bootstrap/Badge";
// import logo from '../../assets/img/logo-tjp.png'
// import { useCart } from '../../store/ContextReducer';
// import { useNavigate } from 'react-router-dom'


// const Navbar = () => {
//   let navigate = useNavigate()
//   const data = useCart();
//   const [cartView, setCarView] = useState(false);
//     const [showMediaIcons, setShowMediaIcons] = useState(false);
//     const [offeringsDropdown, setOfferingsDropdown] = useState(false);
//     const [dropdownActive, setDropdownActive] = useState(false);

//     const closeDropdown = () => {
//         setShowMediaIcons(!showMediaIcons);
//       };
    
//       // console.log(localStorage.getItem("token"));
//     const handleDropdownClick = () => {
//         setDropdownActive((prev) => !prev);
//       };
    
//       const handleLogout = () => {
//         // console.log("test01");
//         localStorage.removeItem("token");
//         navigate("/login");
//       };

//     return (
//       <div className="new-navbar-container">
//         <div className="new-logo">
//           <Link  to="/">
//           <img src={logo} alt="Logo" />
//           </Link>
//         </div>
  
//         <nav id="new-navbar" className={showMediaIcons ? 'new-navbar active' : 'new-navbar'}>
//           <ul>
//             <li>
//               <Link to="/" className="new-nav-link"  onClick={() => {window.scrollTo(0, 0);closeDropdown();}}>HOME</Link>
//             </li>
//             <li>
//               <Link to="/about" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >ABOUT</Link>
//             </li>
//             {/* <li>
//               <Link to="/OrderOnline" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >ORDER ONLINE</Link>
//             </li> */}
//             {/* <li>
//               <Link to="/whyporridge" className="new-nav-link" onClick={closeDropdown} >WHY PORRIDGE</Link>
//             </li> */}
//             {/* <li>
//               <Link to="/whysoups" className="new-nav-link" onClick={closeDropdown} >WHY SOUPS</Link>
//             </li> */}

// <li className="new-dropdown" onClick={handleDropdownClick} >
//               <Link to="/milletproducts" className="new-nav-link"  onClick={() => window.scrollTo(0, 0)}>
//                 <span>ORDER ONLINE &nbsp;</span> <i className="bi bi-chevron-down"></i>
//               </Link>
//               <ul className={dropdownActive ? 'active' : ''}>
//                 <li><Link to="/milletproducts" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}}>Ready To Cook</Link></li>
//                 <li><Link to="/OrderOnline" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >Ready To Eat</Link></li>
                
//                 {/* <li><Link to="/whysoups" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >WHY SOUPS</Link></li> */}
//                 {/* <li><Link to="#" className="new-nav-link" onClick={closeDropdown} >FAQS</Link></li> */}
//               </ul>
//             </li>


//             {/* <li className="new-dropdown" onClick={handleDropdownClick} >
//               <Link to="/blogspage" className="new-nav-link"  onClick={() => window.scrollTo(0, 0)}>
//                 <span>BLOGS &nbsp;</span> <i className="bi bi-chevron-down"></i>
//               </Link>
//               <ul className={dropdownActive ? 'active' : ''}>
//                 <li><Link to="/blogspage" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >BLOGS</Link></li>         
//                 <li><Link to="/whyporridge" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >WHY PORRIDGE</Link></li>
//                 <li><Link to="/whysoups" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >WHY SOUPS</Link></li>
//               </ul>
//             </li> */}

//             {/* <li className="new-dropdown" onClick={handleDropdownClick} >
//               <Link to="/superfoodsmillet" className="new-nav-link"  onClick={() => window.scrollTo(0, 0)}>
//                 <span>SUPER FOODS MILLET &nbsp;</span> <i className="bi bi-chevron-down"></i>
//               </Link>
//               <ul className={dropdownActive ? 'active' : ''}>
//                 <li><Link to="/superfoodsmillet" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >SUPER FOODS MILLET</Link></li>
//                 <li><Link to="/millettypes" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}}>MILLETS AND THE TYPES </Link></li>
//                 <li><Link to="/blogspage" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >BLOGS</Link></li>         
//                 <li><Link to="/whyporridge" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >WHY PORRIDGE</Link></li>
//                 <li><Link to="/whysoups" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >WHY SOUPS</Link></li>
//                 <li><Link to="#" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >YOUR HEALTH</Link></li>
//                 <li><Link to="#" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >FAQS</Link></li>
//               </ul>
//             </li> */}
//             <li>
//               <Link to="/contact" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >CONTACT US</Link>
//             </li>
//             {localStorage.getItem("token") ? (
//             <li>
//               <Link to="/orderhistory" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >MY ORDERS</Link>
//             </li>):null}
//             { !localStorage.getItem("token") ? (
//                 <div className="d-flex">
//             {/* <li>
//             <Link className="new-nav-link" to="/signup" onClick={() => window.scrollTo(0, 0)}>
//                     SIGN UP
//                   </Link>
//             </li> */}
//             <li>
//             <Link to="/Cart" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}}>CART {"   "}
//                      <Badge pill bg="danger">
//                       {data.length}
//                     </Badge>
//                      </Link>
//             </li>
//             <li>
//             <Link className="new-nav-link" to="/Login" onClick={() => window.scrollTo(0, 0)}>
           
//                     LOGIN
//                   </Link>
//             </li>
//             </div>
//                  ) : (
//                   <div className="d-flex">
//                      <div className="d-flex">
//                       <li>
//                   {/* <div
//                     className="new-nav-link"
//                     // className="btn bg-white text-success mx-2"
//                     // onClick={() => {
//                     //   setCarView(true);
//                     // }}
//                   > */}
//                      <Link to="/Cart" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}}>CART {"   "}
//                      <Badge pill bg="danger">
//                       {data.length}
//                     </Badge>
//                      </Link>
//                     {/* My Cart {"   "} */}
//                     {/* <Badge pill bg="danger">
//                       {data.length}
//                     </Badge> */}
//                   {/* </div> */}
//                   </li>
//                   </div>
//                   <li>
//                   <div
//                     className="new-nav-link "
//                     // className="btn bg-white text-danger mx-2"
                    
//                     onClick={() => {window.scrollTo(0, 0);handleLogout();}}
//                   >
//                     LOGOUT
//                   </div>
//                   </li>
//                   <div>
//                     {" "}
//                   {/* <li>
//                     <Link
//                       className="new-nav-link "
//                       aria-current="page"
//                       to="/orderhistory"
//                     >
//                       MYORDERS
//                     </Link>
//                   </li> */}
//                   <li>
//                     <Link
//                       className="new-nav-link "
//                       aria-current="page"
//                       to="/Profile"
//                     >
//                       PROFILE
//                     </Link>
//                   </li>
//                   </div>
//                   </div>
//                   )}
//           </ul>
//         </nav>
  
//         <div className="new-hamburger-menu">
//           <a onClick={() => setShowMediaIcons(!showMediaIcons)}>
//             <GiHamburgerMenu className="hamburger-icon"/>
//           </a>
//         </div>
//       </div>
//     );
//   };
  
//   export default Navbar;


import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import './navbar.css'
import Badge from "react-bootstrap/Badge";
import logo from '../../assets/img/logo-tjp.png'
import { useCart } from '../../store/ContextReducer';
import { useNavigate } from 'react-router-dom'
import LocationModal from '../../pages/general/location/Location';
import { useAuth } from '../../store/AuthContext';
import { Button } from '@mui/material';


const Navbar = () => {
  let navigate = useNavigate()
  const { logout,isAuthenticated } = useAuth(); 
  const data = useCart();
  const [cartView, setCarView] = useState(false);
    const [showMediaIcons, setShowMediaIcons] = useState(false);
    const [offeringsDropdown, setOfferingsDropdown] = useState(false);
    const [dropdownActive, setDropdownActive] = useState(false);
    const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
    const [myCity, setMyCity] = useState('');

    const closeDropdown = () => {
        setShowMediaIcons(!showMediaIcons);
      };
    
      // console.log(localStorage.getItem("token"));
    const handleDropdownClick = () => {
        setDropdownActive((prev) => !prev);
      };
    
      const handleLogout = async() => {
        // console.log("test01");
        // localStorage.removeItem("token");
        await logout();
        navigate("/AuthPage");
      };

      const handleCloseModal = () => {
        setIsLocationModalOpen(false);
      };
      const handleOpenModal = () => {
        setIsLocationModalOpen(true);
      };
    
      useEffect(() => {
        const fetchSelectedCity = async () => {
          const city = localStorage.getItem("myCity");
          if (city) {
            setMyCity(city);
          } else {
            setIsLocationModalOpen(true); // Open modal if no city is found
          }
          // console.log('city', city); 
        };
        fetchSelectedCity();
      }, []);
    
      const handleCityChange = (city) => {
        setMyCity(city); // Ensure this updates the city state
        localStorage.setItem("myCity", city); // Store in localStorage for persistence
      };

    return (
      <div className="new-navbar-container">
        <div className="new-logo d-flex">
          <Link  to="/">
          <img src={logo} alt="Logo" style={{marginRight:'15px'}} />
          </Link>
          {/* <Button onClick={handleOpenModal}>Open Cities</Button> */}
      {/* <Button  onClick={handleOpenModal}> */}
        {/* <div>
        <Button onClick={handleOpenModal}>Open Cities</Button>
        <LocationModal open={isLocationModalOpen} onClose={handleCloseModal} onCitySelect={handleCityChange} />
        </div> */}
        </div>
  
        <nav id="new-navbar" className={showMediaIcons ? 'new-navbar active' : 'new-navbar'}>
          <ul>
            <li>
              <Link to="/" className="new-nav-link"  onClick={() => {window.scrollTo(0, 0);closeDropdown();}}>HOME</Link>
            </li>
            <li>
              <Link to="/about" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >ABOUT</Link>
            </li>
            {/* <li>
              <Link to="/OrderOnline" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >ORDER ONLINE</Link>
            </li> */}
            {/* <li>
              <Link to="/whyporridge" className="new-nav-link" onClick={closeDropdown} >WHY PORRIDGE</Link>
            </li> */}
            {/* <li>
              <Link to="/whysoups" className="new-nav-link" onClick={closeDropdown} >WHY SOUPS</Link>
            </li> */}

<li className="new-dropdown" onClick={handleDropdownClick} >
              <Link to="/milletproducts" className="new-nav-link"  onClick={() => window.scrollTo(0, 0)}>
                <span>ORDER ONLINE &nbsp;</span> <i className="bi bi-chevron-down"></i>
              </Link>
              <ul className={dropdownActive ? 'active' : ''}>
                <li><Link to="/milletproducts" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}}>Ready To Cook</Link></li>
                <li><Link to="/OrderOnline" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >Ready To Eat</Link></li>
                
                {/* <li><Link to="/whysoups" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >WHY SOUPS</Link></li> */}
                {/* <li><Link to="#" className="new-nav-link" onClick={closeDropdown} >FAQS</Link></li> */}
              </ul>
            </li>


            {/* <li className="new-dropdown" onClick={handleDropdownClick} >
              <Link to="/blogspage" className="new-nav-link"  onClick={() => window.scrollTo(0, 0)}>
                <span>BLOGS &nbsp;</span> <i className="bi bi-chevron-down"></i>
              </Link>
              <ul className={dropdownActive ? 'active' : ''}>
                <li><Link to="/blogspage" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >BLOGS</Link></li>         
                <li><Link to="/whyporridge" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >WHY PORRIDGE</Link></li>
                <li><Link to="/whysoups" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >WHY SOUPS</Link></li>
              </ul>
            </li> */}

            {/* <li className="new-dropdown" onClick={handleDropdownClick} >
              <Link to="/superfoodsmillet" className="new-nav-link"  onClick={() => window.scrollTo(0, 0)}>
                <span>SUPER FOODS MILLET &nbsp;</span> <i className="bi bi-chevron-down"></i>
              </Link>
              <ul className={dropdownActive ? 'active' : ''}>
                <li><Link to="/superfoodsmillet" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >SUPER FOODS MILLET</Link></li>
                <li><Link to="/millettypes" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}}>MILLETS AND THE TYPES </Link></li>
                <li><Link to="/blogspage" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >BLOGS</Link></li>         
                <li><Link to="/whyporridge" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >WHY PORRIDGE</Link></li>
                <li><Link to="/whysoups" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >WHY SOUPS</Link></li>
                <li><Link to="#" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >YOUR HEALTH</Link></li>
                <li><Link to="#" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >FAQS</Link></li>
              </ul>
            </li> */}
            <li>
              <Link to="/contact" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >CONTACT US</Link>
            </li>
            {localStorage.getItem("token") ? (
            <li>
              <Link to="/orderhistory" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}} >MY ORDERS</Link>
            </li>):null}
            { !localStorage.getItem("token") ? (
                <div className="d-flex">
            {/* <li>
            <Link className="new-nav-link" to="/signup" onClick={() => window.scrollTo(0, 0)}>
                    SIGN UP
                  </Link>
            </li> */}
            <li>
            <Link to="/Cart" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}}>CART {"   "}
                     <Badge pill bg="danger">
                      {data.length}
                    </Badge>
                     </Link>
            </li>
            <li>
            <Link className="new-nav-link" to="/AuthPage" onClick={() => window.scrollTo(0, 0)}>
           
           SIGNIN
         </Link>
            {/* <Link className="new-nav-link" to="/Login" onClick={() => window.scrollTo(0, 0)}>
           
                    LOGIN
                  </Link> */}
            </li>
            </div>
                 ) : (
                  <div className="d-flex">
                     <div className="d-flex">
                      <li>
                  {/* <div
                    className="new-nav-link"
                    // className="btn bg-white text-success mx-2"
                    // onClick={() => {
                    //   setCarView(true);
                    // }}
                  > */}
                     <Link to="/Cart" className="new-nav-link" onClick={() => {window.scrollTo(0, 0);closeDropdown();}}>CART {"   "}
                     <Badge pill bg="danger">
                      {data.length}
                    </Badge>
                     </Link>
                    {/* My Cart {"   "} */}
                    {/* <Badge pill bg="danger">
                      {data.length}
                    </Badge> */}
                  {/* </div> */}
                  </li>
                  </div>
                  <li>
                  <div
                    className="new-nav-link "
                    // className="btn bg-white text-danger mx-2"
                    
                    onClick={() => {window.scrollTo(0, 0);handleLogout();}}
                  >
                    LOGOUT
                  </div>
                  </li>
                  <div>
                    {" "}
                  {/* <li>
                    <Link
                      className="new-nav-link "
                      aria-current="page"
                      to="/orderhistory"
                    >
                      MYORDERS
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      className="new-nav-link "
                      aria-current="page"
                      to="/Profile"
                    >
                      PROFILE
                    </Link>
                  </li>
                  </div>
                  </div>
                  )}
          </ul>
        </nav>
  
        <div className="new-hamburger-menu">
          <a onClick={() => setShowMediaIcons(!showMediaIcons)}>
            <GiHamburgerMenu className="hamburger-icon"/>
          </a>
        </div>
      </div>
    );
  };
  
  export default Navbar;