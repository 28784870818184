// import React, { useState } from "react";
// import {
//   Card,
//   Typography,
//   Tabs,
//   Tab,
//   Box,
// } from "@mui/material";
// import { styled } from "@mui/system";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import img1 from '../../../../assets/home/franchise/im01.jpeg'
// import img2 from '../../../../assets/home/franchise/img02.jpeg'

// const FranchiseModel = () => {
//   const [selectedTab, setSelectedTab] = useState(0);

//   const franchises = [
//     {
//       category: "outlets",
//       image: img1,
//       // image: "https://via.placeholder.com/576x731",
//       name: "Shop Place 1",
//       address: "123 Main St",
//     },
//     {
//       category: "outlets",
//       image: img2,
//       name: "Shop Place 2",
//       address: "456 Elm St, Banjara Hills, Hyderabad, Telangana State",
//     },
//     {
//       category: "franchises",
//       image: img1,
//       name: "Shop Place 3",
//       address: "789 Oak St, MG Road, Bangalore, Karnataka",
//     },
//     {
//       category: "franchises",
//       image: img2,
//       name: "Shop Place 4",
//       address: "101 Maple St, Indiranagar, Bangalore, Karnataka",
//     },
//     {
//       category: "franchises",
//       image: img1,
//       name: "Shop Place 5",
//       address: "202 Pine St, Whitefield, Bangalore, Karnataka",
//     },
//     {
//       category: "franchises",
//       image: img2,
//       name: "Shop Place 6",
//       address: "101 Maple St, Indiranagar, Bangalore, Karnataka",
//     },
//     {
//       category: "franchises",
//       image: img1,
//       name: "Shop Place 7",
//       address: "202 Pine St, Whitefield, Bangalore, Karnataka",
//     },
//   ];

//   const handleTabChange = (event, newValue) => {
//     setSelectedTab(newValue);
//   };

//   const StyledCard = styled(Card)({
//     position: "relative",
//     borderRadius: "15px",
//     overflow: "hidden",
//     width: "406px",
//     height: "501px",
//     flexShrink: 0,
//     margin: "50px 10px", // Add margin between cards
//   });

//   const StyledImage = styled("img")({
//     width: "100%",
//     height: "100%",
//     objectFit: "cover",
//     transition: "transform 0.5s, opacity 0.5s",
//     "&:hover": {
//       transform: "scale(1.3)",
//       opacity: 0.7,
//     },
//   });

//   const Overlay = styled(Box)({
//     position: "absolute",
//     bottom: 0,
//     width: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.6)",
//     color: "#fff",
//     padding: "50px",
//     // paddingTop:'50px',
//     textAlign: "center",
//     opacity: 0,
//     transition: "opacity 0.3s",
//     "&:hover": {
//       transform: "scale(1.3)",
//       opacity: 1,
//     },
//   });

//   const Heading = styled(Typography)({
//     fontFamily: "Arial, sans-serif",
//     fontSize: "36px",
//     fontWeight: "bold",
//     color: "#FD7E4B",
//     textAlign: "center",
//     marginBottom: "20px",
//     marginTop: "20px",
//     textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
//   });

//   const tabsStyles = {
//     fontFamily: "Arial",
//     fontSize: "26px",
//     fontStyle: "normal",
//     fontWeight: 700,
//     lineHeight: "100%",
//     marginLeft:'50px'
//   };

//   return (
//     <div style={{ paddingBottom: "50px", paddingTop: "60px",backgroundColor:'#fff' }}>
//       <Heading>Our Franchise</Heading>
//       <Tabs
//         value={selectedTab}
//         onChange={handleTabChange}
//         aria-label="Franchise Tabs"
//         TabIndicatorProps={{ style: { backgroundColor: "#FD7E4B" } }} // Update the color of the tab indicator to red
//       >
//         <Tab
//           label="Our Outlets"
//           style={{
//             ...tabsStyles,
//             color: selectedTab === 0 ? "#FD7E4B" : "#434343",
//           }}
//         />
//         <Tab
//           label="Our Franchise"
//           style={{
//             ...tabsStyles,
//             color: selectedTab === 1 ? "#FD7E4B" : "#434343",
//           }}
//         />
//       </Tabs>
//       <Swiper
//         spaceBetween={50}
//         slidesPerView={3}
//         pagination={{ clickable: true }}
//       >
//         {franchises
//           .filter((franchise) =>
//             selectedTab === 0
//               ? franchise.category === "outlets"
//               : franchise.category === "franchises"
//           )
//           .map((franchise, index) => (
//             <SwiperSlide key={index}>
//               <StyledCard spacing="1">
//                 <StyledImage
//                   src={franchise.image}
//                   alt={`Franchise ${index + 1}`}
//                 />
//                 <Overlay>
//                   <Typography variant="h6">{franchise.name}</Typography>
//                   <Typography>{franchise.address}</Typography>
//                 </Overlay>
//               </StyledCard>
//             </SwiperSlide>
//           ))}
//       </Swiper>
//     </div>
//   );
// };

// export default FranchiseModel;

// import React, { useState } from "react";
// import { Card, Typography, Tabs, Tab, Box, useMediaQuery } from "@mui/material";
// import { styled } from "@mui/system";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import img1 from "../../../../assets/home/franchise/im01.jpeg";
// import img2 from "../../../../assets/home/franchise/img02.jpeg";

// const FranchiseModel = () => {
//   const [selectedTab, setSelectedTab] = useState(0);
//   const isMobile = useMediaQuery("(max-width:600px)");

//   const franchises = [
//     {
//       category: "outlets",
//       image: img1,
//       name: "Shop Place 1",
//       address: "123 Main St",
//     },
//     {
//       category: "outlets",
//       image: img2,
//       name: "Shop Place 2",
//       address: "456 Elm St, Banjara Hills, Hyderabad, Telangana State",
//     },
//     {
//       category: "franchises",
//       image: img1,
//       name: "Shop Place 3",
//       address: "789 Oak St, MG Road, Bangalore, Karnataka",
//     },
//     {
//       category: "franchises",
//       image: img2,
//       name: "Shop Place 4",
//       address: "101 Maple St, Indiranagar, Bangalore, Karnataka",
//     },
//     {
//       category: "franchises",
//       image: img1,
//       name: "Shop Place 5",
//       address: "202 Pine St, Whitefield, Bangalore, Karnataka",
//     },
//     {
//       category: "franchises",
//       image: img2,
//       name: "Shop Place 6",
//       address: "101 Maple St, Indiranagar, Bangalore, Karnataka",
//     },
//     {
//       category: "franchises",
//       image: img1,
//       name: "Shop Place 7",
//       address: "202 Pine St, Whitefield, Bangalore, Karnataka",
//     },
//   ];

//   const handleTabChange = (event, newValue) => {
//     setSelectedTab(newValue);
//   };

//   const StyledCard = styled(Card)({
//     position: "relative",
//     borderRadius: "15px",
//     overflow: "hidden",
//     width: isMobile ? "90%" : "406px",
//     height: "501px",
//     flexShrink: 0,
//     margin: isMobile ? "20px auto" : "50px 10px",
//   });

//   const StyledImage = styled("img")({
//     width: "100%",
//     height: "100%",
//     objectFit: "cover",
//     transition: "transform 0.5s, opacity 0.5s",
//     "&:hover": {
//       transform: "scale(1.3)",
//       opacity: 0.7,
//     },
//   });

//   const Overlay = styled(Box)({
//     position: "absolute",
//     bottom: 0,
//     width: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.6)",
//     color: "#fff",
//     padding: "20px",
//     textAlign: "center",
//     opacity: 0,
//     transition: "opacity 0.3s",
//     "&:hover": {
//       transform: "scale(1.3)",
//       opacity: 1,
//     },
//   });

//   const Heading = styled(Typography)({
//     fontFamily: "Arial, sans-serif",
//     fontSize: "36px",
//     fontWeight: "bold",
//     color: "#FD7E4B",
//     textAlign: "center",
//     marginBottom: "20px",
//     marginTop: "20px",
//     textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
//   });

//   const tabsStyles = {
//     fontFamily: "Arial",
//     fontSize: "26px",
//     fontStyle: "normal",
//     fontWeight: 700,
//     lineHeight: "100%",
//     marginLeft: "50px",
//   };

//   return (
//     <div style={{ paddingBottom: "50px", paddingTop: "60px", backgroundColor: "#fff" }}>
//       <Heading>Our Franchise</Heading>
//       <Tabs
//         value={selectedTab}
//         onChange={handleTabChange}
//         aria-label="Franchise Tabs"
//         TabIndicatorProps={{ style: { backgroundColor: "#FD7E4B" } }}
//       >
//         <Tab
//           label="Our Outlets"
//           style={{
//             ...tabsStyles,
//             color: selectedTab === 0 ? "#FD7E4B" : "#434343",
//           }}
//         />
//         <Tab
//           label="Our Franchise"
//           style={{
//             ...tabsStyles,
//             color: selectedTab === 1 ? "#FD7E4B" : "#434343",
//           }}
//         />
//       </Tabs>
//       <Swiper
//         spaceBetween={isMobile ? 10 : 50}
//         slidesPerView={isMobile ? 1 : 3}
//         pagination={{ clickable: true }}
//       >
//         {franchises
//           .filter((franchise) =>
//             selectedTab === 0
//               ? franchise.category === "outlets"
//               : franchise.category === "franchises"
//           )
//           .map((franchise, index) => (
//             <SwiperSlide key={index}>
//               <StyledCard spacing="1">
//                 <StyledImage
//                   src={franchise.image}
//                   alt={`Franchise ${index + 1}`}
//                 />
//                 <Overlay>
//                   <Typography variant="h6">{franchise.name}</Typography>
//                   <Typography>{franchise.address}</Typography>
//                 </Overlay>
//               </StyledCard>
//             </SwiperSlide>
//           ))}
//       </Swiper>
//     </div>
//   );
// };

// export default FranchiseModel;

import React, { useState } from "react";
import { Card, Typography, Tabs, Tab, Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import img1 from "../../../../assets/home/franchise/im01.jpeg";
import img2 from "../../../../assets/home/franchise/img02.jpeg";
import img3 from "../../../../assets/home/franchise/img03.jpg";
import img4 from "../../../../assets/home/franchise/img04.jpg";
import SwiperCore, { Autoplay, Pagination } from 'swiper';


SwiperCore.use([Autoplay, Pagination]);

const FranchiseModel = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");

  const franchises = [
    {
      category: "outlets",
      image: img1,
      name: "",
      address: "",
    },
    {
      category: "outlets",
      image: img2,
      name: "",
      address: "",
    },
    {
      category: "outlets",
      image: img3,
      name: "",
      address: "",
    },
    {
      category: "outlets",
      image: img4,
      name: "",
      address: "",
    },
    {
      category: "franchises",
      image: img1,
      name: "Shop Place 3",
      address: "789 Oak St, MG Road, Bangalore, Karnataka",
    },
    {
      category: "franchises",
      image: img2,
      name: "Shop Place 4",
      address: "101 Maple St, Indiranagar, Bangalore, Karnataka",
    },
    {
      category: "franchises",
      image: img1,
      name: "Shop Place 5",
      address: "202 Pine St, Whitefield, Bangalore, Karnataka",
    },
    {
      category: "franchises",
      image: img2,
      name: "Shop Place 6",
      address: "101 Maple St, Indiranagar, Bangalore, Karnataka",
    },
    {
      category: "franchises",
      image: img1,
      name: "Shop Place 7",
      address: "202 Pine St, Whitefield, Bangalore, Karnataka",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const StyledCard = styled(Card)({
    position: "relative",
    borderRadius: "15px",
    overflow: "hidden",
    width: isMobile ? "90%" : "406px",
    height: "501px",
    flexShrink: 0,
    margin: isMobile ? "20px auto" : "50px 10px",
  });

  const StyledImage = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "transform 0.5s, opacity 0.5s",
    "&:hover": {
      transform: "scale(1.3)",
      opacity: 0.7,
    },
  });

  const Overlay = styled(Box)({
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: "#fff",
    padding: "20px",
    textAlign: "center",
    opacity: 0,
    transition: "opacity 0.3s",
    "&:hover": {
      transform: "scale(1.3)",
      opacity: 1,
    },
  });

  const Heading = styled(Typography)({
    fontFamily: "Arial, sans-serif",
    fontSize: "36px",
    fontWeight: "bold",
    color: "#FD7E4B",
    textAlign: "center",
    marginBottom: "20px",
    marginTop: "20px",
    zIndex: 2,
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
  });

  const tabsStyles = {
    fontFamily: "Arial",
    fontSize: isMobile ? "16px" : "26px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "100%",
    zIndex: 2,
    marginLeft: isMobile ? "0" : "50px",
  };

  const tabsContainerStyles = {
    display: "flex",
    justifyContent: isMobile ? "center" : "flex-start",
  };

  return (
    <div style={{ paddingBottom: "50px", paddingTop: "60px",  backgroundColor: "#FDF0DD",  }}>
      <Heading>Visit Our Outlet</Heading>
      <p style={{textAlign:"center",fontWeight:"bold"}}>Shalivahana Nagar, Sarita Arcade, Malakpet Colony, Saidabad, Hyderabad, Telangana 500036</p>
       
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="Franchise Tabs"
        TabIndicatorProps={{ style: { backgroundColor: "#FD7E4B" } }}
        sx={tabsContainerStyles}
      >
        {/* <Tab
          label="Our Outlet"
          sx={{
            ...tabsStyles,
            color: selectedTab === 0 ? "#FD7E4B" : "#434343",
            "&.Mui-selected": { color: "#FD7E4B" },
          }}
          style={{ zIndex: 0, position: 'relative' }}
        /> */}
        
        {/* <Tab
          label="Our Franchise"
          sx={{
            ...tabsStyles,
            color: selectedTab === 1 ? "#FD7E4B" : "#434343",
            "&.Mui-selected": { color: "#FD7E4B" },
          }}
          style={{ zIndex: 0, position: 'relative' }}
        /> */}
      </Tabs>

      <Swiper
        spaceBetween={isMobile ? 10 : 50}
        slidesPerView={isMobile ? 1 : 3}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        style={{ zIndex: 0, position: 'relative' }}
       
      >
        
        {franchises
          .filter((franchise) =>
            selectedTab === 0
              ? franchise.category === "outlets"
              : franchise.category === "franchises"
          )
          .map((franchise, index) => (
            <SwiperSlide key={index}>
              
              <StyledCard spacing="1">
                <StyledImage
                  src={franchise.image}
                  alt={`Franchise ${index + 1}`}
                  
                />
                <Overlay>
                  <Typography variant="h6"   >{franchise.name}</Typography>
                  <Typography>{franchise.address}</Typography>
                </Overlay>
              </StyledCard>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default FranchiseModel;
