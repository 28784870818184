import React from 'react'
import Header from './Header'
import FoodForEveryone from './FoodForEveryone'
import EatingRight from './EatingRight'
import './porridge.css'

function WhyPorridgePage() {
  return (
    <div className='porridge'>
      <Header/>
      <FoodForEveryone/>
      {/* <EatingRight/> */}
    </div>
  )
}

export default WhyPorridgePage
