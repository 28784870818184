import React from 'react'
import BlogContent from '../../components/BlogContent'
import BlogComment from '../../components/BlogComment'

const BlogsPage = () => {
  return (
    <div>
        
      <BlogContent/>
      <BlogComment/>
    </div>
  )
}

export default BlogsPage
