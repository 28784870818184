import React from 'react'
import OnlineOrderMenu from './OnlineOrderMenu'
import Details from '../../offerings/Details'
import Helmet from '../../../components/helmet'
// import CitiesHeader from '../components/CitiesHeader'

function OnlineOrderPage() {
  return (
    <div>
      {/* <CitiesHeader/> */}
    
    <OnlineOrderMenu/>


    <Details/>
    </div>
  )
}

export default OnlineOrderPage
